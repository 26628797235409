<template>
  <div class="right">
    <span class="spen">我的余额</span>
    <div class="yue">
      <div class="yue_L">
        <span class="big">￥{{ Number(money).toFixed(2) }}</span>
      </div>
      <div class="yue_C">
        <div class="quanbu">
          <img src="../../../assets/order/quanbu.png" alt="" />
          <span class="quanbu_Y1">全部余额：</span>
          <span class="quanbu_Y2">￥{{ Number(money).toFixed(2) }}</span>
        </div>
        <div class="quanbu">
          <img src="../../../assets/order/xiangqing.png" alt="" />
          <span class="quanbu_Y1">余额详情：</span>
          <span class="quanbu_Y2 quanbu_Y3" @click="dialogTableVisible = true"
            >查看</span
          >
        </div>
        <div class="quanbu">
          <img src="../../../assets/order/zhanghu_yue.png" alt="" />
          <span class="quanbu_Y1">账户状态：</span>
          <span class="quanbu_Y2">有效</span>
        </div>
      </div>
      <div></div>
      <!-- <router-link :to="{ name: 'Chongzhi' }" tag="div" class="yue_R"
        >去充值</router-link
      > -->
    </div>
    <div class="menu">
      <div class="qiehuan">
        <div
          class="daohang"
          :class="[
            { bian: index == TabIndex },
            { 'border-right-line': index != TabList.length - 1 },
          ]"
          v-for="(item, index) in paihang"
          :key="index"
          @click="selectedTabItem(index, item.id)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="jilu">历史充值记录</div>
    </div>
    <Loading v-if="isLoading" />
    <div v-else>
      <div v-if="TabIndex == 0">
        <el-table :data="data1" style="width: 100%">
          <el-table-column prop="storeName" :label="$t('common.shop')" width="230">
          </el-table-column>
          <el-table-column prop="amount" label="金额"> </el-table-column>
          <el-table-column prop="rechargeType" label="支付方式">
          </el-table-column>
          <el-table-column prop="rechargeStatus" label="状态">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="TabIndex == 1">
        <el-table :data="data2" style="width: 100%">
          <el-table-column prop="storeName" :label="$t('common.shop')" width="230">
          </el-table-column>
          <el-table-column prop="amount" label="金额"> </el-table-column>
          <el-table-column prop="rechargeType" label="支付方式">
          </el-table-column>
          <el-table-column prop="rechargeStatus" label="状态">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination
      @current-change="pagechange"
      :current-page="page.current"
      style="text-align: right;margin-top: 20px"
      background
      :total="page.total"
      :page-size="page.size"
      layout="prev, pager, next, jumper, ->, total"
    >
    </el-pagination>
    <div class="tishi">
      提示：系统仅显示您两年之内的余额明细，更早的余额明细不再显示。
    </div>
    <el-dialog title="余额详情" :visible.sync="dialogTableVisible">
      <el-table :data="gridData">
        <el-table-column
          property="storeName"
          label="店铺名称"
        ></el-table-column>
        <el-table-column property="balance" label="当前余额"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      paihang: [
        {
          id: 1,
          title: '近三个月充值明细',
        },
        {
          id: 2,
          title: '三个月前充值明细',
        },
      ],
      TabIndex: 0,
      TabList: [],
      data1: [],
      data2: [],
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
      money: '',
      isLoading: true,
      gridData: [],
      dialogTableVisible: false,
    };
  },
  created() {
    this.getdata1();
    this.getmoney();
    this.getyue();
  },
  methods: {
    //余额详情
    getyue() {
      post('api/storeaccount/getShopMemberAccountStorelist').then((res) => {
        res.data.data.forEach((req) => {
          req.balance = Number(req.balance).toFixed(2);
        });
        this.gridData = res.data.data;
      });
    },
    getmoney() {
      get('api/account/selAccount').then((res) => {
        this.money = res.data.data.balance;
      });
    },
    pagechange(p) {
      this.isLoading = true;
      this.page.current = p;
      if (this.TabIndex == 0) {
        get(
          'api/account/getstoreaccountlist?timetype=0&pageNum=' +
            p +
            '&pageSize=10'
        ).then((res) => {
          res.data.data.list.forEach((req) => {
            if (req.rechargeType == 0) {
              req.rechargeType = '支付宝支付';
            }
            if (req.rechargeType == 1) {
              req.rechargeType = '微信支付';
            }
            if (req.rechargeType == 2) {
              req.rechargeType = '充值卡';
            }
            if (req.rechargeStatus == 0) {
              req.rechargeStatus = '待支付';
            }
            if (req.rechargeStatus == 1) {
              req.rechargeStatus = '未完成';
            }
            if (req.rechargeStatus == 2) {
              req.rechargeStatus = '已完成';
            }
            if (req.rechargeStatus == 3) {
              req.rechargeStatus = this.$t('common.Cancelled');
            }
          });
          this.data1 = res.data.data.list;
          this.isLoading = false;
        });
      } else if (this.TabIndex == 1) {
        get(
          'api/account/getstoreaccountlist?timetype=1&pageNum=' +
            p +
            '&pageSize=10'
        ).then((res) => {
          res.data.data.list.forEach((req) => {
            if (req.rechargeType == 0) {
              req.rechargeType = '支付宝支付';
            }
            if (req.rechargeType == 1) {
              req.rechargeType = '微信支付';
            }
            if (req.rechargeType == 2) {
              req.rechargeType = '充值卡';
            }
            if (req.rechargeStatus == 0) {
              req.rechargeStatus = '待支付';
            }
            if (req.rechargeStatus == 1) {
              req.rechargeStatus = '未完成';
            }
            if (req.rechargeStatus == 2) {
              req.rechargeStatus = '已完成';
            }
            if (req.rechargeStatus == 3) {
              req.rechargeStatus = this.$t('common.Cancelled');
            }
          });
          this.data2 = res.data.data.list;
          this.isLoading = false;
        });
      }
    },
    getdata1() {
      get(
        'api/account/getstoreaccountlist?timetype=0&pageNum=1&pageSize=10'
      ).then((res) => {
        res.data.data.list.forEach((req) => {
          if (req.rechargeType == 0) {
            req.rechargeType = '支付宝支付';
          }
          if (req.rechargeType == 1) {
            req.rechargeType = '微信支付';
          }
          if (req.rechargeType == 2) {
            req.rechargeType = '充值卡';
          }
          if (req.rechargeStatus == 0) {
            req.rechargeStatus = '待支付';
          }
          if (req.rechargeStatus == 1) {
            req.rechargeStatus = '未完成';
          }
          if (req.rechargeStatus == 2) {
            req.rechargeStatus = '已完成';
          }
          if (req.rechargeStatus == 3) {
            req.rechargeStatus = this.$t('common.Cancelled');
          }
        });
        this.data1 = res.data.data.list;
        this.isLoading = false;
        if (this.TabIndex == 0) {
          this.page.total = res.data.data.total;
        }
      });
    },
    getdata2() {
      get(
        'api/account/getstoreaccountlist?timetype=1&pageNum=1&pageSize=10'
      ).then((res) => {
        res.data.data.list.forEach((req) => {
          if (req.rechargeType == 0) {
            req.rechargeType = '支付宝支付';
          }
          if (req.rechargeType == 1) {
            req.rechargeType = '微信支付';
          }
          if (req.rechargeType == 2) {
            req.rechargeType = '充值卡';
          }
          if (req.rechargeStatus == 0) {
            req.rechargeStatus = '待支付';
          }
          if (req.rechargeStatus == 1) {
            req.rechargeStatus = '未完成';
          }
          if (req.rechargeStatus == 2) {
            req.rechargeStatus = '已完成';
          }
          if (req.rechargeStatus == 3) {
            req.rechargeStatus = this.$t('common.Cancelled');
          }
        });
        this.data2 = res.data.data.list;
        this.isLoading = false;
        if (this.TabIndex == 1) {
          this.page.total = res.data.data.total;
        }
      });
    },
    selectedTabItem(index, e) {
      this.TabIndex = index;
      this.page.current = 1;
      if (this.TabIndex == 0) {
        this.getdata1();
      }
      if (this.TabIndex == 1) {
        this.getdata2();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 976px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  padding-right: 30px;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .yue {
    width: 100%;
    height: 130px;
    margin-top: 63px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .yue_L {
      font-size: 26px;
      color: #777;
      font-weight: 600;
      .big {
        font-size: 46px;
      }
    }
    .yue_C {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .quanbu {
        display: flex;
        font-size: 16px;
        img {
          width: 20px;
          height: 20px;
        }
        .quanbu_Y1 {
          color: #333;
          margin-left: 15px;
        }
        .quanbu_Y2 {
          color: #545454;
          margin-left: 20px;
        }
        .quanbu_Y3 {
          color: #1850ff;
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
    .yue_R {
      width: 112px;
      height: 38px;
      background: #1850ff;
      color: #fff;
      line-height: 38px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .menu {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    .qiehuan {
      margin-top: 60px;
      height: 42px;
      display: flex;
      align-items: center;
      cursor: default;
      .daohang {
        font-size: 15px;
        color: #272727;
        margin-right: 25px;
        cursor: pointer;
      }
      .bian {
        color: #1a4fff;
      }
    }
    .jilu {
      margin-top: 60px;
      color: #363636;
    }
  }
  .tishi {
    font-size: 14px;
    color: #929292;
    margin-top: 10px;
  }
}
</style>
